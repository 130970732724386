import React from 'react'
import styles from './Fund.module.scss'
import ChartMobile from '../../assets/image/chart-mobile.png'
import Chart from '../../assets/image/chart.png'
import Hand from '../../assets/image/handSmall.svg'

function Fund() {
  return (
    <div id="fund" className={styles.fund}>
      <div className={styles.fund__title}>FAIR TOKENOMICS</div>
      <div className={styles.fund__wrapper}>
        <span className={`${styles.fund__decorative} ${styles['fund__decorative--second']}`}></span>
        <span className={`${styles.fund__decorative} ${styles['fund__decorative--third']}`}></span>
        <span className={`${styles.fund__decorative} ${styles['fund__decorative--first']}`}></span>
        <img src={ChartMobile} alt="chart" className={`${styles.fund__image} ${styles['fund__image--mobile']}`} />
        <img src={Chart} alt="chart" className={`${styles.fund__image} ${styles['fund__image--desktop']}`} />
        <div className={styles.fund__block}>
          <img className={styles['fund__block-img']} src={Hand} alt="hand" />
          <div className={styles['fund__block-top']}>Total supply</div>
          <div className={styles['fund__block-center']}>911 911 911</div>
          <div className={styles['fund__block-bottom']}>$ZMBI</div>
        </div>
      </div>
    </div>
  )
}

export default Fund
