import React, { useState, useEffect } from 'react'
import styles from './Timer.module.scss'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import TimerImage from '../../assets/image/timer.png'

dayjs.extend(duration)

function Timer() {
  const [timer, setTimer] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  })

  const updateTimer = () => {
    const now = dayjs()
    const target = dayjs('2024-11-14T12:00:00.00Z')
    const difference = target.diff(now)
    const duration = dayjs.duration(difference)

    setTimer({
      days:
        String(Math.floor(duration.asDays())) === '0'
          ? '00'
          : String(Math.floor(duration.asDays())).length === 1
            ? `0${String(Math.floor(duration.asDays()))}`
            : String(Math.floor(duration.asDays())),
      hours:
        String(duration.hours()) === '0'
          ? '00'
          : String(duration.hours()).length === 1
            ? `0${String(duration.hours())}`
            : String(duration.hours()),
      minutes:
        String(duration.minutes()) === '0'
          ? '00'
          : String(duration.minutes()).length === 1
            ? `0${String(duration.minutes())}`
            : String(duration.minutes()),
      seconds:
        String(duration.seconds()) === '0'
          ? '00'
          : String(duration.seconds()).length === 1
            ? `0${String(duration.seconds())}`
            : String(duration.seconds())
    })
  }

  useEffect(() => {
    updateTimer()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      updateTimer()
    }, 1000)
  }, [timer])

  return (
    <div className={styles.timer}>
      <img src={TimerImage} className={styles.timer__timer} />
      <div className={styles.timer__coming}>Zombis launch <br /> coming</div>
      <div className={styles.timer__count}>
        {`00:00:00:00`}
      </div>
      <div className={styles.timer__date}>
        <div className={styles.timer__date__bottom}>Thursday, the 14th</div>
      </div>
      <div className={styles.timer__button}>
        <button style={{cursor: 'pointer'}} className={styles.timer__join} onClick={() => window.open("https://t.me/ZombiBotBeta_bot/play", "_blank")}>
            join the zombi apocalypse hype
        </button>
      </div>
    </div>
  )
}

export default Timer
