import React, { useState } from 'react';
import Close from '../../assets/image/faq/close.png'
import Open from '../../assets/image/faq/open.png'
import styles from './AccordionItem.module.scss'

export default function AccordionItem ({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.container}>
            <div className={styles.container__openBtn} onClick={toggleAccordion}>
                <div>
                    {title}
                </div>
                <div>
                    <img src={isOpen ? Open : Close} alt="logo" />
                </div>
            </div>
            {isOpen && (
                <div className={styles.container__content}>
                    {content}
                </div>
            )}
        </div>
    );
};

