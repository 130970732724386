import React from 'react'
import { Provider } from 'react-redux'
import { store } from './store'
import { Outlet } from 'react-router-dom'
import {Bounce, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import './App.scss'

function App() {
  return (
    <Provider store={store}>
        <ToastContainer
            autoClose={3000}
            theme="dark"
            limit={1}
            transition={Bounce}
        />
      <Outlet />
    </Provider>
  )
}

export default App
