import styles from './Faqs.module.scss'
import AccordionItem from "../AccordionItem/AccordionItem";
import {FAQS_LIST} from "../../constants/faqs/faqs-list";

export default function Faqs() {
    return (
    <div className={styles['main-container']}>
      <div className={styles['containerTitle']}>
          <span className={styles['frequently-asked-questions']}>
        Frequently Asked
        <br/>
        Questions
      </span>
      </div>
        <div className={styles['flex-row-b']}>
            {FAQS_LIST.map((faq) => (
                <AccordionItem title={faq.title} content={faq.content} />
            ))}
        </div>
        <div className={styles['frame-2e']}>
            <span className={styles['question-mark-2f']}>?</span>
            <div className={styles['image']}/>
            <span className={styles['question-mark-30']}>?</span>
        </div>
    </div>
    )
}
