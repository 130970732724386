import {log} from "qrcode/lib/core/galois-field";

export const useCopy = () => {
  return (copyText) => {
    navigator.clipboard?.writeText && navigator.clipboard.writeText(copyText).then(() => {
      console.log('address_copied')
    }).catch((err) => {
      console.log('error_copy_address')
    });
  }
}
