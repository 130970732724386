export const FAQS_LIST = [
    {
        title: 'What chain is $ZMBI on?',
        content: '$ZMBI will launch on the Solana blockchain. Later, we’ll migrate to our own custom chain built on a network of underground tunnels.'
    },
    {
        title: 'What’s the goal of launching $ZMBI?',
        content: 'During the global apocalypse, $ZMBI will be your golden ticket into secret government bunkers for crypto geeks and serve as the only currency accepted in the post-apocalyptic world.'
    },
    {
        title: 'Will $ZMBI still be available after the apocalypse?',
        content: 'Of course! We’ll keep the blockchain running by generating electricity with geese. In a world without laws or ethics, goose-powered crypto will be the future'
    },
    {
        title: 'Will $ZMBI be listed on exchanges?',
        content: 'Right after the TGE, the team will list the token on a DEX, followed by a CEX listing. Eventually, we’ll aim for DEADX — the final resting place for tokens with a death wish'
    },
    {
        title: 'What’s the max supply of $ZMBI?',
        content: 'Apocalyptic — 911,911,911.'
    },
    {
        title: 'Official links to the project',
        content: 'Follow us — or perish uninformed:\n' +
            'https://x.com/cyber_zombi_bot\n' +
            'https://t.me/ZombiBotChat\n' +
            'https://t.me/ZombiBotBeta_bot/play\n' +
            'Stay connected, because when the end comes, at least you’ll know where the memes are.\n'
    },
    {
        title: 'What will be the price of $ZMBI?',
        content: 'The price will be determined right after the pre-sale ends. Or, you know, after the world ends — whichever comes first.'
    },
    {
        title: 'Who’s behind $ZMBI?',
        content: 'Obviously, Deepstate. The secret government has always been in the crypto game. $ZMBI is our final project. Originally, we planned to sell these tokens to the elites, but then we realized that DEGENs are the true chosen ones. So, we took it public — because if the world’s going to burn, it might as well be fun.'
    },
    {
        title: 'What happens after the token is launched?',
        content: 'You’ll see for yourself. If you survive, that is.'
    },
    {
        title: 'Will you have merch?',
        content: 'Yes! Sweatshirts with slogans like “Nobody survived, but I made X10” and hats that say “The token will save you — if not, at least it’s funny.” Big hit in the bunkers.'
    },
    {
        title: 'Do you plan any metaverse integrations?',
        content: 'We’re aiming for integration with megaton nuclear warheads. Way more immersive'
    },
    {
        title: 'Do you have a roadmap?',
        content: 'Yes, we’ll be cruising down Route 666 straight onto the Highway to Hell.'
    },
    {
        title: 'When can I buy an island with $ZMBI?',
        content: 'Your best shot is somewhere between the listing and the EMP blast from the nuclear explosion. Timing is everything — don’t miss your window!'
    },
    {
        title: 'Why am I still reading this dumb FAQ?',
        content: 'Because deep down, you’re already an investor. Just accept it.'
    },
]
