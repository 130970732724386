import React, { useState } from 'react'
import { sendCheckNft } from '../../api/check-nft/check-nft'

export const useCheckNft = () => {
  const [processing, setProcessing] = useState(false)
  const [failure, setFailure] = useState(false)
  const [checkResult, setCheckResult] = useState(null)

  const checkNft = async (email) => {
    try {
      if (processing) {
        return
      }

      setProcessing(true)
      setFailure(false)

      const { data } = await sendCheckNft(email)

      setCheckResult(data)
      setProcessing(false)
      setFailure(false)
      return data
    } catch (err) {
      setProcessing(false)
      setFailure(true)

      throw err
    }
  }

  return {
    checkNft,
    processing,
    failure,
    checkResult
  };
}
