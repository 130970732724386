import React from 'react';
import Marquee from 'react-fast-marquee';
import { ReactComponent as IconAsterisk } from '../../assets/image/asterisk.svg';

import styles from './LandingMarquee.module.scss';

function LandingMarquee() {
  return (
    <div className={styles.wrapper}>
      <Marquee pauseOnHover={true}>
        <ul className={styles.list}>
          {Array(5).fill(null).map((_, index) => (
            <React.Fragment key={index}>
              <li className={styles.list__item}>
                <span className={styles.list__highlight}>51% of funds</span>
                {' '}
                locked to the liquidity pool
              </li>
              <li className={styles.list__item}>
                <IconAsterisk width="10" height="10" className={styles.list__icon} />
              </li>
              <li className={styles.list__item}>
                <span className={styles.list__highlight}>49% to the bunker</span>
                {' '}
                republic development
              </li>
              <li className={styles.list__item}>
                <IconAsterisk width="10" height="10" className={styles.list__icon} />
              </li>
            </React.Fragment>
          ))}
        </ul>
      </Marquee>
    </div>
  )
}

export default LandingMarquee;
