import React, { useState, useRef, useEffect } from 'react'
import styles from './Slider.module.scss'
import Arrow from '../../assets/image/arrow.svg'
import Image1 from '../../assets/image/slider/1.png'
import Image2 from '../../assets/image/slider/2.png'
import Image4 from '../../assets/image/slider/3.png'
import Image3 from '../../assets/image/slider/4.png'
import Image5 from '../../assets/image/slider/5.png'
import Image6 from '../../assets/image/slider/6.png'

function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const sliderRef = useRef(null)

  const cardWidth = 395

  const visibleCards = Math.floor(windowWidth / cardWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const cards = [
    {
      image: Image1,
      title: 'Pre-Apocalypse Prep',
      subTitle: '[ Q4 2024 ]',
      date: 'Apr 24',
      color: '#72DC61',
      descriptionOne: {
        title: 'Social Media Takeover: ',
        text: 'Flood Telegram, Twitter/X and Discord with apocalyptic memes and election conspiracies.'
      },
      descriptionTwo: {
        title: 'Air-Bomb Campaign: ',
        text: 'First wave of airdrops with cryptic missions, flight schedules, and "explosive" rewards.'
      },
      descriptionThee: {
        title: 'Pre-sale Madness: ',
        text: '50% of tokens (25% Vote Round + 25% Voters Bonus) up for grabs before November 5, because democracy deserves a price tag.'
      }
    },
    {
      image: Image2,
      title: 'Election Night Chaos',
      subTitle: '[ November 2024 ]',
      date: 'Aug 24',
      color: '#FF0202',
      descriptionOne: {
        title: 'TGE Launch: ',
        text: '$ZMBI goes live after election results — may the strongest survive.'
      },
      descriptionTwo: {
        title: 'DEX Listing: ',
        text: 'For those brave enough to gamble on the end of the world.'
      },
      descriptionThee: {
        title: 'Memes or Bust: ',
        text: 'Kick off the #PostElectionApocalypse campaign, blaming all world problems on your least favorite candidate.'
      }
    },
    {
      image: Image3,
      title: 'Nuclear Winter Rally',
      subTitle: '[ Q1 2025 ]',
      date: 'Sep 24',
      color: '#FF3A8C',
      descriptionOne: {
        title: 'CEX Listing: ',
        text: 'Expand to centralized exchanges - because even during the apocalypse, liquidity matters.'
      },
      descriptionTwo: {
        title: 'Partnerships: ',
        text: 'Secure collabs with b-movie studios, bunker manufacturers, and post-apocalyptic influencers'
      },
      descriptionThee: {
        title: 'Geese Energy Initiative: ',
        text: 'Begin beta-testing goose-powered blockchain infrastructure.'
      }
    },
    {
      image: Image4,
      title: 'Wasteland Dominance',
      subTitle: '[ Q2 2025 ]',
      date: 'Dec 24',
      color: '#FFDD6C',
      descriptionOne: {
        title: 'Bunker Access Utility: ',
        text: 'Token holders gain early access to private crypto-geek bunkers.'
      },
      descriptionTwo: {
        title: 'Nuclear Metaverse: ',
        text: 'Deploy immersive, megaton-integrated virtual experiences for survivors (if they still have Wi-Fi).'
      },
      descriptionThee: {
        title: 'Merch Drop: ',
        text: 'Launch the "Nobody Survived, But I Made X10" clothing line - perfect attire for life underground.'
      }
    },
    // {
    //   image: Image5,
    //   title: 'Pre-Apocalypse Prep',
    //   subTitle: '[ Q4 2024 ]',
    //   date: '2025',
    //   color: '#65ACFF',
    //   descriptionOne: {
    //     title: 'Social Media Takeover: ',
    //     text: 'Flood Telegram, Twitter/X and Discord with apocalyptic memes and election conspiracies.'
    //   },
    //   descriptionTwo: {
    //     title: 'Air-Bomb Campaign: ',
    //     text: 'First wave of airdrops with cryptic missions, flight schedules, and "explosive" rewards.'
    //   },
    //   descriptionThee: {
    //     title: 'Pre-sale Madness: ',
    //     text: '30% of tokens (15% Presale Round + 15% Presale Round Bonus) up for grabs before November 5, because democracy deserves a price tag.'
    //   }
    // },
    {
      image: Image6,
      title: 'Endgame: Life After the Boom',
      subTitle: '[ Whenever It Happens ]',
      date: '2025',
      color: '#6CFFDF',
      descriptionOne: {
        title: 'Goosechain Mainnet Launch: ',
        text: 'Migrate fully to the goose-powered underground blockchain. Sustainability never looked so absurd.'
      },
      descriptionTwo: {
        title: 'Post-Apocalypse Adoption: ',
        text: '$ZMBI becomes the official wasteland currency - because even after the bombs fall, degens always find a way.'
      },
      descriptionThee: {
        title: '',
        text: ''
      }
    }
  ]

  const lastIndex = cards.length - 1;

  const handleNext = () => {
    if (currentIndex < cards.length - visibleCards) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  return (
    <div className={styles.slider}>
      <div className={styles.slider__arrows}>
        <button disabled={currentIndex === 0} onClick={handlePrev}>
          <img className={styles.slider__arrows__prev} src={Arrow} alt="arrow-disable" />
        </button>
        <div className={styles.slider__header}>Apocalypse Travel Guide</div>
        <button disabled={currentIndex === lastIndex} onClick={handleNext}>
          <img src={Arrow} alt="arrow" />
        </button>
      </div>
      <div
        className={styles.slider__slider}
        ref={sliderRef}
        style={{ transform: `translateX(-${currentIndex * cardWidth}px)` }}
      >
        {cards.map((item, index) => (
          <div key={index} className={styles.slider__card}>
            <div className={styles.slider__position}>Phase <span style={{color: item.color, paddingLeft: '3px'}}>{index + 1 === cards.length ? 'FINAL' :index + 1}</span></div>
            <img className={styles.slider__card__image} src={item.image} alt="images" />
            <div className={styles.slider__card__bottom}>
              <div className={styles.slider__card__bottom__description}>{item.title}</div>
              <div style={{color: item.color}} className={styles.slider__card__bottom__date}>{item.subTitle}</div>
            </div>
            <div className={styles.slider__descWrapper}>
              <div className={styles.slider__descContainer}>
                <span className={styles.slider__descriptionTitle} style={{color: item.color}}>{item.descriptionOne.title}</span>
                {item.descriptionOne.text}
              </div>
              <div className={styles.slider__descContainer}>
                <span className={styles.slider__descriptionTitle} style={{color: item.color}}>{item.descriptionTwo.title}</span>
                {item.descriptionTwo.text}
              </div>
              <div className={styles.slider__descContainer}>
                <span className={styles.slider__descriptionTitle} style={{color: item.color}}>{item.descriptionThee.title}</span>
                {item.descriptionThee.text}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.slider__pagination}>
        <ul className={styles.slider__pagination__list}>
          {cards.map((_, index) => (
            <li className={`${styles.slider__pagination__item} ${index === currentIndex ? styles.slider__pagination__item__active : ''}`}></li>
          ))}
        </ul>
      </div>
      <button style={{cursor: 'pointer'}} className={styles.btnTimer} onClick={() => window.open("https://t.me/ZombiBotBeta_bot/play", "_blank")}>
          join the zombi apocalypse hype
      </button>
    </div>
  )
}

export default Slider
