import styles from './Roadmap.module.scss'
import {useEffect, useState} from "react";
import Slider from "../Slider/Slider";

export default function Roadmap() {
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div>
            {windowSize > 1225 ? (
                <div className={styles['main-container']}>
      <span className={styles['apocalypse-travel-guide']}>
        Apocalypse
        <br/>
        Travel Guide
      </span>
                    <div className={styles['group']}/>
                    <div className={styles['group-1']}>
                        <div className={styles['flex-row-cd']}>
                            <div className={styles['image']}/>
                            <div className={styles['phase']}>
                                <span className={styles['phase-1']}>Phase </span>
                                <span className={styles['text-3-1']}>1</span>
                            </div>
                            <div className={styles['social-media-takeover']}><span className={styles['social-media-takeover-2']}>Social Media Takeover: </span>
                                <span className={styles['flood-telegram-twitter-x']}>Flood Telegram, Twitter/X and Discord with apocalyptic memes and election conspiracies.</span>
                            </div>
                            <div className={styles['air-bomb-campaign']}>
                                <span className={styles['air-bomb-campaign-3']}>Air-Bomb Campaign: </span>
                                <span className={styles['first-wave-airdrops']}>First wave of airdrops with cryptic missions, flight schedules, and "explosive" rewards.</span>
                            </div>
                        </div>
                        <div className={styles['flex-row']}>
                            <div className={styles['pre-sale-madness']}>
                                <span className={styles['pre-sale-madness-4']}>Vote Round Madness: </span>
                                <span className={styles['text-9']}>50% of tokens (25% Vote Round + 25% Voters Bonus) up for grabs before November 5, because democracy deserves a price tag.</span>
                            </div>
                            <span className={styles['pre-apocalypse-prep']}>Pre-Apocalypse Prep</span>
                            <span className={styles['q4-2024']}>[ Q4 2024 ]</span>
                        </div>
                    </div>
                    <div className={styles['backs']}/>
                    <div className={styles['rectangle']}>
                        <div className={styles['flex-row-b']}>
                            <div className={styles['phase-5']}>
                                <span className={styles['phase-2']}>Phase </span>
                                <span className={styles['text-d']}>2</span>
                            </div>
                            <div className={styles['rectangle-6']}/>
                        </div>
                        <div className={styles['flex-row-b-7']}>
                            <div className={styles['tge-launch']}>
                                <span className={styles['tge-launch-8']}>TGE Launch: </span>
                                <span className={styles['zmbi-goes-live']}>
          $ZMBI goes live after election results — may the strongest
          survive.
        </span>
                            </div>
                            <div className={styles['dex-listing']}>
                                <span className={styles['dex-listing-9']}>DEX Listing: </span>
                                <span className={styles['gamble-end-world']}>
          For those brave enough to gamble on the end of the world.
        </span>
                            </div>
                        </div>
                        <div className={styles['flex-row-a']}>
                            <div className={styles['rectangle-a']}/>
                            <div className={styles['rectangle-b']}/>
                            <div className={styles['rectangle-c']}/>
                            <div className={styles['rectangle-d']}/>
                            <div className={styles['rectangle-e']}/>
                            <div className={styles['rectangle-f']}/>
                            <div className={styles['rectangle-10']}/>
                            <div className={styles['rectangle-11']}/>
                            <div className={styles['rectangle-12']}/>
                            <div className={styles['rectangle-13']}/>
                            <div className={styles['rectangle-14']}/>
                            <div className={styles['rectangle-15']}/>
                            <div className={styles['rectangle-16']}/>
                            <div className={styles['rectangle-17']}/>
                            <div className={styles['rectangle-18']}/>
                            <div className={styles['rectangle-19']}/>
                            <div className={styles['rectangle-1a']}/>
                            <div className={styles['rectangle-1b']}/>
                            <div className={styles['rectangle-1c']}/>
                            <div className={styles['rectangle-1d']}/>
                            <div className={styles['rectangle-1e']}/>
                            <div className={styles['memes-or-bust']}>
                                <span className={styles['memes-or-bust-1f']}>Memes or Bust: </span>
                                <span className={styles['kick-off-post-election-apocalypse']}>
          Kick off the #PostElectionApocalypse campaign, blaming all world
          problems on your least favorite candidate.
        </span>
                            </div>
                            <div className={styles['rectangle-20']}/>
                            <span className={styles['election-night-chaos']}>Election Night Chaos</span>
                            <span className={styles['november']}>[ November 2024 ]</span>
                            <div className={styles['rectangle-21']}/>
                        </div>
                    </div>
                    <div className={styles['image-22']}/>
                    <div className={styles['rectangle-23']}>
                        <div className={styles['flex-row-cf']}>
                            <div className={styles['phase-24']}>
                                <span className={styles['phase-25']}>Phase </span>
                                <span className={styles['text-17']}>3</span>
                            </div>
                            <div className={styles['rectangle-26']}/>
                        </div>
                        <span className={styles['nuclear-winter-rally']}>Nuclear Winter Rally</span>
                        <span className={styles['q1-2025']}>[ Q1 2025 ]</span>
                        <div className={styles['geese-energy-initiative']}>
                            <span className={styles['geese-energy-initiative-27']}>Geese Energy Initiative: </span>
                            <span className={styles['begin-beta-testing']}>Begin beta-testing goose-powered blockchain infrastructure.</span>
                        </div>
                        <div className={styles['flex-row-efa']}>
                            <div className={styles['rectangle-28']}/>
                            <div className={styles['rectangle-29']}/>
                            <div className={styles['rectangle-2a']}/>
                            <div className={styles['rectangle-2b']}/>
                            <div className={styles['rectangle-2c']}/>
                            <div className={styles['rectangle-2d']}/>
                            <div className={styles['rectangle-2e']}/>
                            <div className={styles['rectangle-2f']}/>
                            <div className={styles['rectangle-30']}/>
                            <div className={styles['rectangle-31']}/>
                            <div className={styles['rectangle-32']}/>
                            <div className={styles['rectangle-33']}/>
                            <div className={styles['rectangle-34']}/>
                            <div className={styles['rectangle-35']}/>
                            <div className={styles['rectangle-36']}/>
                            <div className={styles['rectangle-37']}/>
                            <div className={styles['rectangle-38']}/>
                            <div className={styles['rectangle-39']}/>
                            <div className={styles['rectangle-3a']}/>
                            <div className={styles['cex-listing']}>
                                <span className={styles['cex-listing-3b']}>CEX Listing: </span>
                                <span className={styles['expand-to-exchanges']}>
          Expand to centralized exchanges - because even during the
          apocalypse, liquidity matters.
        </span>
                            </div>
                            <div className={styles['partnerships']}>
                                <span className={styles['partnerships-3c']}>Partnerships: </span>
                                <span className={styles['secure-collabs']}>
          Secure collabs with b-movie studios, bunker manufacturers, and
          post-apocalyptic influencers.
        </span>
                            </div>
                            <div className={styles['rectangle-3d']}/>
                            <div className={styles['rectangle-3e']}/>
                            <div className={styles['rectangle-3f']}/>
                            <div className={styles['rectangle-40']}/>
                        </div>
                    </div>
                    <div className={styles['image-41']}/>
                    <div className={styles['group-42']}>
                        <div className={styles['flex-column-c']}>
                            <div className={styles['phase-43']}>
                                <span className={styles['phase-44']}>Phase</span>
                                <span className={styles['empty']}> </span>
                                <span className={styles['number-4']}>4</span>
                            </div>
                            <div className={styles['bunker-access-utility']}>
                                <span className={styles['bunker-access-utility-45']}>Bunker Access Utility: </span>
                                <span className={styles['token-holders-access']}>Token holders gain early access to private crypto-geek bunkers.</span>
                            </div>
                            <div className={styles['nuclear-metaverse']}>
                                <span className={styles['nuclear-metaverse-46']}>Nuclear Metaverse: </span>
                                <span className={styles['deploy-virtual-experiences']}>
          Deploy immersive, megaton-integrated virtual experiences for
          survivors (if they still have Wi-Fi).
        </span>
                            </div>
                            <div className={styles['merch-drop']}>
                                <span className={styles['merch-drop-47']}>Merch Drop: </span>
                                <span className={styles['launch-clothing-line']}>
          Launch the "Nobody Survived, But I Made X10" clothing line -
          perfect attire for life underground.
        </span>
                            </div>
                        </div>
                        <div className={styles['flex-column']}>
                            <span className={styles['wasteland-dominance']}>Wasteland Dominance</span>
                            <span className={styles['q2-2025']}>[ Q2 2025 ]</span>
                        </div>
                    </div>
                    <div className={styles['image-48']}/>
                    <div className={styles['group-70']}>
                        <div className={styles['flex-column-ae']}>
                            <div className={styles['phase-final']}>
                                <span className={styles['phase-71']}>Phase</span>
                                <span className={styles['phase-72']}> </span>
                                <span className={styles['final']}>Final</span>
                            </div>
                            <div className={styles['goosechain-mainnet-launch']}>
        <span className={styles['goosechain-mainnet-launch-73']}>Goosechain Mainnet Launch: </span>
                                <span className={styles['goosechain-mainnet-launch-74']}>
          Migrate fully to the goose-powered underground blockchain.
          Sustainability never looked so absurd.
        </span>
                            </div>
                            <div className={styles['post-apocalypse-adoption']}>
        <span className={styles['post-apocalypse-adoption-75']}>Post-Apocalypse Adoption: </span>
                                <span className={styles['post-apocalypse-adoption-76']}>
          $ZMBI becomes the official wasteland currency - because even after
          the bombs fall, degens always find a way.
        </span>
                            </div>
                        </div>
                        <div className={styles['flex-column-e']}>
      <span className={styles['endgame-life-after-the-boom']}>
        Endgame: <br/>
        Life After the Boom
      </span>
                            <span className={styles['endgame-life-after-the-boom-77']}>
        [ Whenever It Happens ]
      </span>
                        </div>
                    </div>
                    <div className={styles['image-78']}/>
                    <button  className={styles.btnTimer} onClick={() => window.open("https://t.me/ZombiBotBeta_bot/play", "_blank")}>
                        join the zombi bot
                    </button>
                </div>
            ) : (<Slider />)}
        </div>
    );
}
