import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const getVotesCount = async () => {
  try {
    const data = axios.get(`${API_URL}/api/landingpage/votes`)

    return data
  } catch (e) {
    throw e
  }
}

export const getVotesStatistic = async () => {
  try {
    const data = axios.get(`${API_URL}/api/account/votes-statistic/blockchain/wallet-totals`)

    return data
  } catch (e) {
    throw e
  }
}

export const sendVotes = async (partyName) => {
  try {
    const data = axios.post(`${API_URL}/api/landingpage/increment`, { partyName: partyName })

    return data
  } catch (e) {
    throw e
  }
}
