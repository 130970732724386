import React, { useState, useEffect } from 'react'
import Header from '../../components/Header/Header'
import Fund from '../../components/Fund/Fund'
import Rocket from '../../components/Rocket/Rocket'
import Journey from '../../components/Journey/Journey'
import Footer from '../../components/Footer/Footer'
import Navigation from '../../components/Navigation/Navigation'
import TimerWrapper from '../../components/TimerWrapper/TimerWrapper'
import './Landing.module.scss'
import Airdrop from "../../components/Airdrop/Airdrop";
import Roadmap from "../../components/Roadmap/Roadmap";
import Faqs from "../../components/Faqs/Faqs";

const sections = [
  { id: 'header', content: <Header /> },
  // { id: 'airdrop', content: <Airdrop /> },
  // { id: 'timer', content: <TimerWrapper /> },
  { id: 'rocket', content: <Rocket /> },
  // { id: 'fund', content: <Fund /> },
  { id: 'roadmap', content: <Roadmap /> },
  { id: 'faqs', content: <Faqs /> },
  { id: 'journey', content: <Journey /> },
  { id: 'footer', content: <Footer /> }
]

function Landing() {
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // const showTimer = windowSize < 1100
  //
  // const filteredSections = sections.filter((section) => {
  //   if (section.id === 'timer') {
  //     return showTimer
  //   }
  //   return true
  // })

  return (
    <div className="scroll-container">
      <Navigation />
      <div style={{ maxWidth: '1920px', margin: '0 auto'}} className="wrapper">
        {sections.map((section) => (
            <div
                key={section.id}
                id={section.id}
                className="section"
                // style={{ height: '100vh' }}
            >
              {section.content}
            </div>
        ))}
      </div>
    </div>
  )
}

export default Landing
