import React from 'react'
import styles from './GlitchText.module.scss'

function GlitchText({ className, children }) {
  className = className ?? ''

  return (
    <div className={`${styles.glitch} ${className}`}>
      <div className={styles.glitch__main}>{children}</div>
      <div className={styles.glitch__top}>{children}</div>
      <div className={styles.glitch__center}>{children}</div>
      <div className={styles.glitch__bottom}>{children}</div>
    </div>
  )
}

export default GlitchText;
