import React from 'react'
import styles from './Footer.module.scss'
import Timer from '../../assets/image/timer.png'
import Hand from '../../assets/image/handSmall.svg'
import { ReactComponent as IconWarning } from '../../assets/image/warning.svg'

function Footer() {
  return (
    <div className={styles.footer}>
      {/*<div className={styles.timer}>*/}
      {/*  <img className={styles.timer__clock} src={Timer} alt="timer" />*/}
      {/*  <div className={styles.timer__text}>*/}
      {/*    <img src={Hand} className={styles.timer__icon} alt="hand" />*/}
      {/*    <div className={styles.timer__zmbi}>$ZMBI</div>*/}
      {/*    <div className={styles.timer__rocketing}>Rocketing</div>*/}
      {/*    <div className={styles.timer__date}>14 November, 2024</div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <nav className={styles.nav}>
          <a
              href="https://zombi-bot.gitbook.io/ciber-zombi-bot/usdzmbis-tokens"
              target="_blank"
              className={styles.nav__item}
          >
              TOKENOMICS
          </a>
        <a
          href="https://x.com/cyber_zombi_bot"
          target="_blank"
          className={styles.nav__item}
        >
          X (TWITTER)
        </a>
        <a href="https://t.me/cyber_zombi_bot" target="_blank" className={styles.nav__item}>
          TELEGRAM
        </a>
        <a
          href="https://zombi-bot.gitbook.io/ciber-zombi-bot"
          target="_blank"
          className={styles.nav__item}
        >
          GITBOOK
        </a>
        <a
          href="https://discord.com/invite/XfF5h5Y7N9"
          target="_blank"
          className={styles.nav__item}
        >
          DISCORD
        </a>
        <a href="https://t.me/ZombiBotBeta_bot/play"  className={styles.nav__button} target="_blank">
          PLAY
        </a>
      </nav>
      <div className={styles.warning}>
        <div className={styles.container}>
          <div className={styles.warning__title}>
            <IconWarning width="20" height="20" className={styles.warning__icon} />
            RISK WARNING:
          </div>
          <div className={styles.warning__content}>
            <p>
              Buying Zombi token ($ZMBI) does not establish an investment contract or offer investment advice. $ZMBI is made ony for entertainment purposes and usage in the Zombi.bot gameplay!
              ZOMBI Bot does not assure or provide any assurances regarding the future token value or performance, and its value may undergo significant fluctuations. Additionally, please be aware that owning a Zombi token ($ZMBI) might be prohibited in your jurisdiction. Please verify compliance with your local laws. By continuing to use this website, you acknowledge that you understand these terms and agree to them.
            </p>
            <p>
              © 2024 Zombi.bot. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
