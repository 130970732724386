import React from 'react'

import LogoIcon from '../../assets/logo.svg'
import LandingMarquee from '../LandingMarquee/LandingMarquee'

import styles from './Navigation.module.scss'

function Navigation() {
  return (
    <div className={styles.wrapper}>
      {/*<LandingMarquee />*/}
      <div className={styles.navigation}>
        <div className={styles.navigation__logo}>
          <img src={LogoIcon} alt="logo" />
        </div>
        <div className={styles.navigation__links}>
          <a
              href="https://zombi-bot.gitbook.io/ciber-zombi-bot/usdzmbis-tokens"
              target="_blank"
              className={styles.navigation__links__item}
          >
            TOKENOMICS
          </a>
          <a
            href="https://x.com/cyber_zombi_bot"
            target="_blank"
            className={styles.navigation__links__item}
          >
            X (TWITTER)
          </a>
          <a
            href="https://t.me/cyber_zombi_bot"
            target="_blank"
            className={styles.navigation__links__item}
          >
            TELEGRAM
          </a>
          <a
            href="https://zombi-bot.gitbook.io/ciber-zombi-bot"
            target="_blank"
            className={styles.navigation__links__item}
          >
            GITBOOK
          </a>
          <a
            href="https://discord.com/invite/XfF5h5Y7N9"
            target="_blank"
            className={styles.navigation__links__item}
          >
            DISCORD
          </a>
          <button style={{cursor: 'pointer'}} className={styles.navigation__links__button}>
            <a href="https://t.me/ZombiBotBeta_bot/play" target="_blank">
              PLAY
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Navigation
