import React from 'react'
import styles from './Journey.module.scss'
import Zombi from '../../assets/image/zombi.png'

function Journey() {
  return (
    <div className={styles.journey}>
      {/*<div className={styles.journey__text}>OUR JOURNEY TO ETERNITY</div>*/}
      <div className={styles.journey__block}>
        <div className={styles.journey__block__left}>
          <div className={styles.journey__block__left__text}>
            USE YOUR REFERRAL LINK TO MULTIPLY GAINS
          </div>
          <button style={{cursor: 'pointer'}} className={styles.journey__block__left__button} onClick={() => window.open("https://t.me/ZombiBotBeta_bot/play", "_blank")}>
            Your referral link
          </button>
        </div>
        <div className={styles.journey__block__right}>
          <img src={Zombi} alt="zombi" />
        </div>
      </div>
    </div>
  )
}

export default Journey
