import React, { useMemo, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from "react-toastify";

import Navigation from '../../components/Navigation/Navigation'
import { useCheckNft } from '../../hooks/check-nft/use-check-nft'

import card30 from '../../assets/image/nft/card_30.png'
import card100 from '../../assets/image/nft/card_100.png'
import card300 from '../../assets/image/nft/card_300.png'
import card700 from '../../assets/image/nft/card_700.png'
import card1500 from '../../assets/image/nft/card_1500.png'
import card3000 from '../../assets/image/nft/card_3000.png'

import { ReactComponent as IconToken } from '../../assets/image/iconToken.svg'

import styles from './CheckNft.module.scss'

const SEARCH_QUERY_EMAIL_KEY = 'mail'

function CheckNft () {
  const [searchParams] = useSearchParams()
  const { checkNft, processing, failure, checkResult} = useCheckNft()
  const [inputEmail, setInputEmail] = useState('')
  const navigate = useNavigate()

  const email = useMemo(() => {
    return searchParams.get(SEARCH_QUERY_EMAIL_KEY)
  }, [searchParams])

  const internalEmail = useMemo(() => {
    return inputEmail || email
  }, [email, inputEmail])

  const showInput = useMemo(() => {
    return (!email && !checkResult) || failure || (inputEmail && processing)
  }, [email, failure, checkResult, inputEmail, processing])

  const checkNftHandler = async (emailAddress) => {
    if (emailAddress) {
      try {
        await checkNft(emailAddress)

        if (email !== emailAddress) {
          navigate({
            search: Array.from(searchParams.keys())
              .filter(curr => curr !== SEARCH_QUERY_EMAIL_KEY)
              .reduce((acc, curr) => {
                acc.push(`${curr}=${searchParams.get(curr)}`)
                return acc;
              }, [`mail=${emailAddress}`]).join('&')
          }, {
            replace: true
          })
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    checkNftHandler(email)
  }, [])

  const imageSrc = useMemo(() => {
    if (processing || failure) {
      return null
    }

    switch (checkResult?.value) {
      case 30: {
        return card30
      }
      case 100: {
        return card100
      }
      case 300: {
        return card300
      }
      case 700: {
        return card700
      }
      case 1500: {
        return card1500
      }
      case 3000: {
        return card3000
      }
      default: {
        return null;
      }
    }
  }, [processing, failure, checkResult])

  let content = null;

  const submitHandler = (e) => {
    e.preventDefault()

    if (processing) {
      return
    }

    checkNftHandler(inputEmail)
  }

  const showNatification = () => {
    toast('Coming soon after TGE');
  }

  if (showInput) {
    content = (
      <div className={styles.section__container}>
        <h1 className={styles.section__title}>
          Enter your email
        </h1>
        <form className={`${styles.form} ${processing ? styles.form_processing : ''}`} onSubmit={submitHandler}>
          <label className={styles.form__label} htmlFor="email">
            Email
          </label>
          <div className={styles.form__field}>
            <div className={styles.form__inputWrap}>
              <input
                type="email"
                name="email"
                id="email"
                className={styles.form__input}
                value={inputEmail}
                readOnly={processing}
                placeholder="dolores.chambers@example.com"
                onChange={e => setInputEmail(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className={styles.form__submit}
            >
              Send
            </button>
          </div>
          {failure && <div className={styles.form__error}>Email not found</div>}
        </form>
      </div>
    )
  } else {
    content = (
      <>
        <div className={styles.section__mainTitleContainer}>
          <div className={styles.section__title}>
            {internalEmail || 'Prepare for future battles with our NFT'}
          </div>
          {internalEmail && (
            <div className={styles.section__title}>
              Check your NFT here!!!
            </div>
          )}
        </div>
        <div className={styles.section__mainContent}>
          <div className={styles.section__wrapperBalance}>
            <div className={styles.section__containerBalance}>
              <div className={styles.section__balance}>
                <div className={styles.section__balance__title}>Your wallet balance:</div>
                <div className={styles.section__balance__nftToken}>
                  <div className={styles.section__balance__container}>
                    <div>
                      Zombi NFT
                    </div>
                    <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                      <IconToken />
                      <div className={styles.section__balance__token}>
                        {checkResult?.value} ZMBI
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {imageSrc ? <img src={imageSrc} alt='Card' /> : <div className={styles.placeholder} />}
            </div>
            <div className={styles.section__mainContentTextContainer}>
              <div className={styles.section__mainContentText}>
                <p>
                  125,000 $ZMBI/per NFT
                </p>
              </div>
              <div className={styles.section__mainContentText}>
                <p>
                  10% of your referral spendings
                </p>
              </div>
              <div className={styles.section__mainContentText}>
                <p>
                  Forever doubled ammo
                </p>
              </div>
            </div>
          </div>
          <div className={styles.section__wrapperLeft}>
            <div className={styles.section__containerButton}>
              <button onClick={showNatification} className={styles.section__containerButton__withdrawal}>
                withdrawal
              </button>
              <button onClick={showNatification} className={styles.section__containerButton__claim}>
                claim
              </button>
            </div>
            <div className={styles.section__wrapperLeft__text}>
              <div>
                Coming soon!!!
              </div>
              <p>
                after TGE
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={`${styles.section} ${showInput ? styles.section_light : ''}`}>
      <Navigation />
      <div className={styles.section__container}>
        {content}
      </div>
    </div>
  )
}

export default CheckNft
